<template>
  <div class="supervisorButton">
    <transition name="el-fade-in-linear">
      <div
        v-if="isShowBtn"
        :class="[buttonClass, { buttonMargin: buttonMargin }]"
      >
        <iq-button
          icon="DriverPlus"
          color="main-dark"
          :size="buttonSize"
          @onClick="handleAddDay"
        >
          {{ buttonText }}
        </iq-button>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  GET_IS_MOBILE,
  GET_SUPERVISOR_QUOTAS_FOR_TABLE,
  GET_SUPERVISOR_SAMPLES,
} from '@/store/actions'
import {
  SUPERVISOR_EDIT_QUOTA,
  SUPERVISOR_EDIT_SAMPLE,
} from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import IqButton from '@/views/ui/components/buttons/IqButton'

export default {
  name: 'AddSupervisorQuotaButton',
  components: { IqButton },
  computed: {
    ...mapGetters({
      quotasList: GET_SUPERVISOR_QUOTAS_FOR_TABLE,
      samplesList: GET_SUPERVISOR_SAMPLES,
      isMobile: GET_IS_MOBILE,
    }),
    isSamplesPage() {
      return ['AdminSupervisorSamples', 'SupervisorSamples'].includes(
        this.$route.name,
      )
    },
    buttonClass() {
      return this.isMobile ? 'mobileSearchButton' : ''
    },
    buttonMargin() {
      return window.innerWidth < '767'
    },
    buttonSize() {
      return this.isMobile ? 'fullWidthMedium' : 'medium'
    },
    isShowBtn() {
      return this.isSamplesPage
        ? this.samplesList.length > 0
        : Object.keys(this.quotasList).length > 0
    },
    buttonText() {
      return this.isSamplesPage
        ? 'Добавить шаблон правил вызова'
        : 'Добавить правило вызова'
    },
  },
  methods: {
    handleAddDay() {
      if (this.isSamplesPage) {
        this.setDialog({ name: SUPERVISOR_EDIT_SAMPLE, visible: true })
      } else {
        this.setDialog({ name: SUPERVISOR_EDIT_QUOTA, visible: true })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.supervisorButton
  +to(1200px)
    width: 100%
</style>
